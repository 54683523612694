import React, { useState } from 'react';
import { BrowserRouter as Router, Route, Routes, useLocation, Form} from "react-router-dom";
import './App.css';
import { Home } from "./pages/home";
import { Bio } from "./pages/bio";
import { Concerts } from "./pages/concerts";
import { Media } from "./pages/media";
import { Contact } from "./pages/contact";
import { HomeDE } from "./pages/homeDE"
import { BioDE } from "./pages/BioDE";
import { ConcertsDE } from "./pages/ConcertsDE"
import { ContactDE } from "./pages/ContactDE"
import { MediaDE } from "./pages/MediaDE";

function App() {
  const location = useLocation()

  return (
    <div className="App">
      <Routes location={location} key={location.pathname}>
        <Route path="/" element={ <Home/>}/>
        <Route path="/bio" element={ <Bio/>}/>
        <Route path="/concerts" element={ <Concerts/>}/>
        <Route path="/media" element={ <Media/>}/>
        <Route path="/contact" element={ <Contact/>}/>
        <Route path="/de/homeseite" element={ <HomeDE/>}/>
        <Route path="/de/bio" element={ <BioDE/>}/>
        <Route path="/de/kalender" element={ <ConcertsDE/>}/>
        <Route path="/de/kontakt" element={ <ContactDE/>}/>
        <Route path="/de/media" element={ <MediaDE/>}/>
      </Routes>
    </div>
  );
}

export default App;