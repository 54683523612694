import React, { useState, useEffect } from "react";
import Header from "../components/Header";
import MenuHamburger from "../components/MenuHamburger"
import Footer from "../components/Footer";
import { Link } from "react-scroll";
import { Helmet } from 'react-helmet';

export const Bio = () => {

  const [language, setLanguage] = useState("en");
  const [showMenuHamburger, setShowMenuHamburger] = useState(true);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [showMobile, setShowMobile] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth > 1024) {
        setShowMobile(false);
        setShowMenuHamburger(false);
      } else {
        setShowMobile(true);
        setShowMenuHamburger(true);
      }
    };

    handleResize();

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <>
    <Helmet>
      <title>Tetiana Bielikova | Biography</title>
      <meta name="description" content="Biography of Tetiana Bielikova" />
    </Helmet>
    <div className="bio-container">
      <div className="background-photo-container">
        {!showMobile ? (
            <img alt="Tetiana-Bielikova-Biography" className="bio-image-background" src="../photos/DSCF7067.webp"/>
            ) : (
            <img alt="Tetiana-Bielikova-Biography" className="bio-image-background-mobile" src="../photos/DSCF6689.webp" />
          )}
      </div>
      {showMenuHamburger && <MenuHamburger isMenuOpen={isMenuOpen} isBioPage={true} language={language} />}
      {!showMenuHamburger && <Header isBioPage={true} language={language}/>}
      <div className="main-bio">
        <div className="bio-quote-wrapper">
          <blockquote className="home-quote bio">“With her, the piano always sounded like what it is - a string instrument, closely related to and partnered with the violin.”</blockquote>
          <blockquote className="quoter bio">- Rhein-Zeitung</blockquote>
        </div>
        <Link to="scrollTarget" smooth={true} duration={1500} className="down-arrow-link">
        <div className="down-arrow-bio">
          <svg className="down-arrow" xmlns="http://www.w3.org/2000/svg" fillRule="evenodd" clipRule="evenodd"><path d="M24 12c0-6.623-5.377-12-12-12s-12 5.377-12 12 5.377 12 12 12 12-5.377 12-12zm-1 0c0-6.071-4.929-11-11-11s-11 4.929-11 11 4.929 11 11 11 11-4.929 11-11zm-11.5 4.828l-3.763-4.608-.737.679 5 6.101 5-6.112-.753-.666-3.747 4.604v-11.826h-1v11.828z"/></svg>
        </div>
        </Link>
        <div className="main-bio-container" id="scrollTarget">
          <div className="biography-container">
            <div className="bio-biography-container">
              <h2 className="bio-biography">Biography</h2>
              <div className="line"></div>
            </div>
            <p className="biography-text">
            Tetiana Bielikova (born 1996) was born in Starobilsk, Ukraine. Her passion for music developed early, and at the age of five, she began her musical education under the guidance of Kseniia Holubieva at the music school in Severodonetsk. During her school years in Severodonetsk (2003-2012), Tetiana actively participated in numerous children's competitions on both national and international levels. One significant international children's competition she took part in was "Virtuosi per musica di pianoforte" in the Czech Republic (2009) in Ústí nad Labem, where she was awarded a diploma as a finalist.  
            </p>
            <p className="biography-text">
            At the age of 15, Tetiana continued her education at the prestigious Kharkiv Specialized Music Boarding School in the class of Svitlana Zakharova. In 2014, at the age of 17, Tetiana began her first year of bachelor studies at the Kharkiv National University of Arts. During her studies at the university in Kharkiv, she performed concerts in various cities in Ukraine and Europe, including performances in the Philharmonies of Kharkiv and Luhansk, as well as concerts as part of festivals in Spain and Greece.
            </p>
            <p className="biography-text">
            Her pursuit of musical excellence led Tetiana to Germany in 2018, where she studied at the Folkwang University of the Arts in the class of Prof. Henri Sigfridsson. In 2021, Tetiana decided to focus on chamber music and accompaniment, starting her Duo Master's program at the Folkwang University of the Arts in the class of Prof. Evgeny Sinaiski. This time in her life stood out as a notable step in her artistic path.
            </p>
            <div className="main-bio-quote-wrapper">
              <blockquote className="home-quote bio-second">
              “Tetiana Bielikova can play in a gripping and virtuoso manner, but always remains binding and connecting in terms of sound.”
              </blockquote>              
              <blockquote className="quoter bio-second">- Rhein-Zeitung</blockquote>
            </div>
            <p className="biography-text">
            Starting from the year 2020, Tetiana performs in a duo with the violinist Dmytro Udovychenko. In recent years, the duo has actively played on various stages across Europe. Tetiana and Dmytro have participated in master classes by Gidon Kremer and Boris Garlitsky. Some concerts of the duo have been recorded for SWR2 Radio ("Die Villa Musica zu Gast im Künstlerhaus Edenkoben SWR2-VIOLINSONATEN") and WDR 3 ("WDR 3 Campus-Konzert).
            </p>
            <p className="biography-text">
            Since 2022, Tetiana has actively dedicated herself to the role of an accompanist. She gained her initial experiences in this role in the bassoon class at the Folkwang University of the Arts in Essen and the violin class at the Robert Schumann Hochschule in Düsseldorf. Additionally, she is involved as an accompanist at the festivals "Sommermusik im Oberen Nagoldtal" and "Die internationalen Musiktage in Starnberg und Seefeld."
            </p>
            <a href="https://dl.dropboxusercontent.com/scl/fi/c6p14f71r1iwtinvpslhj/tetiana_bielikova_presskit.zip?rlkey=fjwgxs5qg695q4gj3pcj8km9g&dl=0" className="contact-links-container" target="_blank">
              <span className='hover-links links-contact' ><svg width="1.7em" viewBox="0 0 24 22" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M9 17L12 20M12 20L15 17M12 20V12M4 15.6458C3.70865 15.3154 3.47348 14.9387 3.30448 14.5307C3.10346 14.0454 3 13.5253 3 13C3 12.4747 3.10346 11.9546 3.30448 11.4693C3.5055 10.984 3.80014 10.543 4.17157 10.1716C4.54301 9.80014 4.98396 9.5055 5.46927 9.30448C5.95457 9.10346 6.47471 9 7 9C7 8.34339 7.12933 7.69321 7.3806 7.08658C7.63188 6.47996 8.00017 5.92876 8.46447 5.46447C8.92876 5.00017 9.47996 4.63188 10.0866 4.3806C10.6932 4.12933 11.3434 4 12 4C12.6566 4 13.3068 4.12933 13.9134 4.3806C14.52 4.63188 15.0712 5.00017 15.5355 5.46447C15.9998 5.92876 16.3681 6.47996 16.6194 7.08658C16.8707 7.69321 17 8.34339 17 9C17.5253 9 18.0454 9.10346 18.5307 9.30448C19.016 9.5055 19.457 9.80014 19.8284 10.1716C20.1999 10.543 20.4945 10.984 20.6955 11.4693C20.8965 11.9546 21 12.4747 21 13C21 13.5253 20.8965 14.0454 20.6955 14.5307C20.5265 14.9387 20.2913 15.3154 20 15.6458" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"></path></svg></span>
              <span className='hover-links links-contact'>Download Biography</span>
            </a>   
          </div>          
        </div>
      </div>
      <Footer/>
    </div>
    </>
  );
};

export default Bio;
