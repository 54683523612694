import React from 'react';

function ConcertItem({ language, date, time, nameOfConcert, place, whoPlays1, whoPlays2, link  }) {
  return (
    <div className='concert'>
      <div className="concert-container">
          <div className="concert-layout">
              <div className="date-time-concert">
                  <span>{date}</span>
                  {time && <span className="margin-left">{time}</span>}
              </div>
              <div className="concert-title-container">
                  <h1 className="concert-title">{nameOfConcert}</h1>
              </div>
              <div className="concert-venue">
              {language === 'en' && (
                <p style={{marginRight:".5rem"}}>Venue:</p>
              )}
              {language === 'de' && (
                <p style={{marginRight:".5rem"}}>Standort:</p>
              )}
              <p>{place}</p>
              </div>
              <div className="concert-participants">
                  <p>{whoPlays1}</p>
                  <p>{whoPlays2}</p>
              </div>
              <div className="more-button-concert">
                <a target='blank' href={link} className={`button-more ${!link && 'inactive-button'}`}>
                    {language === 'en' && (
                        <span style={{marginRight: ".5rem"}}>More infos</span>
                    )}
                    {language === 'de' && (
                        <span style={{marginRight: ".5rem"}}>Mehr Infos</span>
                    )}
                    <div className="more-infos-icon">
                      <svg width="100%" viewBox="0 0 6 10" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                        <path fillRule="evenodd" clipRule="evenodd" d="M5.59695 4.39249C5.75255 4.54809 5.75255 4.80033 5.59695 4.95594L1.53474 9.01813C1.37914 9.17373 1.1269 9.17373 0.9713 9.01813L0.78345 8.8303C0.627847 8.6747 0.627847 8.42243 0.78345 8.26682L4.37608 4.67421L0.78345 1.08158C0.627847 0.925979 0.627847 0.673742 0.78345 0.518138L0.9713 0.330289C1.1269 0.174686 1.37914 0.174686 1.53474 0.330289L5.59695 4.39249Z" fill="currentColor"></path>
                      </svg>
                    </div>
                  </a>
              </div>
          </div>
      </div>
  </div>
  );
}

export default ConcertItem;
