import React, { useState, useEffect } from "react";
import Header from "../components/Header";
import MenuHamburger from "../components/MenuHamburger"
import Footer from "../components/Footer";
import { Link } from "react-scroll";
import { Helmet } from 'react-helmet';

export const BioDE = () => {

  const [language, setLanguage] = useState("de");
  const [showMenuHamburger, setShowMenuHamburger] = useState(true);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [showMobile, setShowMobile] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth > 1024) {
        setShowMobile(false);
        setShowMenuHamburger(false);
      } else {
        setShowMobile(true);
        setShowMenuHamburger(true);
      }
    };

    handleResize();

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <>
    <Helmet>
      <title>Tetiana Bielikova | Biografie</title>
      <meta name="description" content="Biography of Tetiana Bielikova" />
    </Helmet>
    <div className="bio-container">
      <div className="background-photo-container">
        {!showMobile ? (
            <img className="bio-image-background" loading="lazy" src="../photos/DSCF7067.webp"/>
            ) : (
            <img className="bio-image-background-mobile" loading="lazy" src="../photos/DSCF6689.webp" />
          )}
      </div>
      {showMenuHamburger && <MenuHamburger isMenuOpen={isMenuOpen} isBioPage={true} language={language} />}
      {!showMenuHamburger && <Header isBioPage={true} language={language}/>}
      <div className="main-bio">
        <div className="bio-quote-wrapper">
          <blockquote className="home-quote bio">“Dabei kann Tetiana Bielikova durhaus zupackend und virtuos spielen, bleibt dabei klanglich jedoch immer verbindlich und verbindend.”</blockquote>
          <blockquote className="quoter bio">-  Rhein-Zeitung</blockquote>
        </div>
        <Link to="scrollTarget" smooth={true} duration={1500} className="down-arrow-link">
        <div className="down-arrow-bio">
          <svg className="down-arrow" xmlns="http://www.w3.org/2000/svg" fillRule="evenodd" clipRule="evenodd"><path d="M24 12c0-6.623-5.377-12-12-12s-12 5.377-12 12 5.377 12 12 12 12-5.377 12-12zm-1 0c0-6.071-4.929-11-11-11s-11 4.929-11 11 4.929 11 11 11 11-4.929 11-11zm-11.5 4.828l-3.763-4.608-.737.679 5 6.101 5-6.112-.753-.666-3.747 4.604v-11.826h-1v11.828z"/></svg>
        </div>
        </Link>
        <div className="main-bio-container" id="scrollTarget">
          <div className="biography-container">
            <div className="bio-biography-container">
              <h2 className="bio-biography">Biografie</h2>
              <div className="line"></div>
            </div>
            <p className="biography-text">
            Tetiana Bielikova (geb. 1996) wurde in Starobilsk, Ukraine, geboren. Ihre Leidenschaft für die Musik entwickelte sich früh, und im Alter von fünf Jahren begann sie ihre musikalische Ausbildung unter der Anleitung von Kseniia Holubieva an der Musikschule in Severodonetsk. Während ihrer Schuljahre in Severodonetsk (2003-2012) nahm Tetiana aktiv an zahlreichen Kinderwettbewerben auf nationaler und internationaler Ebene teil. Ein bedeutender internationaler Kinderwettbewerb, an dem Tetiana teilnahm, war "Virtuosi per musica di pianoforte" in Tschechien (2009) in Ústí nad Labem, bei dem sie mit einem Diplom als Finalistin ausgezeichnet wurde.
            </p>
            <p className="biography-text">
            Mit 15 Jahren setzte Tetiana ihre Ausbildung an der renommierten Charkiw Special Boarding Music School in der Klasse von Svitlana Zakharova fort. Im Jahr 2014, im Alter von 17 Jahren, begann Tetiana ihr erstes Jahr des Bachelorstudiums an der Kharkiv National University of Arts. Während ihres Studiums an der Universität in Charkiw gab sie Konzerte in verschiedenen Städten der Ukraine und Europas. Dazu gehörten Aufführungen in den Philharmonien von Charkiw und Luhansk sowie Konzerte im Rahmen von Festivals in Spanien und Griechenland.
            </p>
            <p className="biography-text">
            Ihr Streben nach musikalischer Exzellenz führte Tetiana 2018 nach Deutschland, wo sie an der Folkwang Universität der Künste in der Klasse von Prof. Henri Sigfridsson studierte. Im Jahr 2021 entschied sich Tetiana für einen Schwerpunkt in Kammermusik und Korrepetition, und sie begann ihr Duo-Master-Studium an der Folkwang Universität der Künste in der Klasse von Prof. Evgeny Sinaiski. Diese Phase ihres Lebens markierte einen bedeutenden Abschnitt in ihrer künstlerischen Entwicklung.
            </p>
            <div className="main-bio-quote-wrapper">
              <blockquote className="home-quote bio-second">“Bei ihr klang das Klavier stets nach dem, was es ebenfalls ist - ein Saiteninsteument, eng verwandt und verpartnert mit der Violine.”</blockquote>
              <blockquote className="quoter bio-second">- Rhein-Zeitung</blockquote>
            </div>
            <p className="biography-text">
            Ab dem Jahr 2020 tritt Tetiana im Duo mit dem Geiger Dmytro Udovychenko auf. In den letzten Jahren hat das Duo aktiv auf verschiedenen Bühnen in Europa gespielt. Tetiana und Dmytro haben an Meisterkursen von Gidon Kremer und Boris Garlitsky teilgenommen. Einige Konzerte des Duos wurden für das SWR2-Radio (''Die Villa Musica zu Gast im Künstlerhaus Edenkoben SWR2-VIOLINSONATEN'') und WDR 3 (''WDR 3 Campus-Konzert'') aufgezeichnet.
            </p>
            <p className="biography-text">
            Seit 2022 widmet sich Tetiana aktiv der korrepetitorischen Tätigkeit. Ihre ersten Erfahrungen in dieser Rolle sammelte sie in der Fagottklasse an der Folkwang Universität der Künste in Essen sowie in der Violinklasse an der Robert Schumann Hochschule in Düsseldorf. Zudem engagiert sie sich als Korrepetitorin bei den Festivals "Sommermusik im Oberen Nagoldtal" und "Die internationalen Musiktage in Starnberg und Seefeld".
            </p>
            <a href="https://dl.dropboxusercontent.com/scl/fi/c6p14f71r1iwtinvpslhj/tetiana_bielikova_presskit.zip?rlkey=fjwgxs5qg695q4gj3pcj8km9g&dl=0" className="contact-links-container" target="_blank">
              <span className='hover-links links-contact' ><svg width="1.7em" viewBox="0 0 24 22" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M9 17L12 20M12 20L15 17M12 20V12M4 15.6458C3.70865 15.3154 3.47348 14.9387 3.30448 14.5307C3.10346 14.0454 3 13.5253 3 13C3 12.4747 3.10346 11.9546 3.30448 11.4693C3.5055 10.984 3.80014 10.543 4.17157 10.1716C4.54301 9.80014 4.98396 9.5055 5.46927 9.30448C5.95457 9.10346 6.47471 9 7 9C7 8.34339 7.12933 7.69321 7.3806 7.08658C7.63188 6.47996 8.00017 5.92876 8.46447 5.46447C8.92876 5.00017 9.47996 4.63188 10.0866 4.3806C10.6932 4.12933 11.3434 4 12 4C12.6566 4 13.3068 4.12933 13.9134 4.3806C14.52 4.63188 15.0712 5.00017 15.5355 5.46447C15.9998 5.92876 16.3681 6.47996 16.6194 7.08658C16.8707 7.69321 17 8.34339 17 9C17.5253 9 18.0454 9.10346 18.5307 9.30448C19.016 9.5055 19.457 9.80014 19.8284 10.1716C20.1999 10.543 20.4945 10.984 20.6955 11.4693C20.8965 11.9546 21 12.4747 21 13C21 13.5253 20.8965 14.0454 20.6955 14.5307C20.5265 14.9387 20.2913 15.3154 20 15.6458" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"></path></svg></span>
              <span className='hover-links links-contact'>Download Presskit</span>
            </a>   
          </div>          
        </div>
      </div>
      <Footer/>
    </div>
    </>
  );
};

export default BioDE;
