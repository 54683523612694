import React, { useState, useEffect } from "react";
import Header from "../components/Header";
import MenuHamburger from "../components/MenuHamburger"
import Footer from "../components/Footer";
import { Link } from "react-scroll";
import ConcertItem from "../components/ConcertItem"
import { Helmet } from 'react-helmet';

export const ConcertsDE = () => {

  const [language, setLanguage] = useState("de");
  const [showMenuHamburger, setShowMenuHamburger] = useState(true);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [showMobile, setShowMobile] = useState(false);
  
  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth > 1024) {
        setShowMobile(false);
        setShowMenuHamburger(false);
      } else {
        setShowMobile(true);
        setShowMenuHamburger(true);
      }
    };

    handleResize();

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <>
    <Helmet>
      <title>Tetiana Bielikova | Kalender</title>
      <meta name="description" content="Upcoming concerts" />
    </Helmet>
    <div className="calendar-container">
        <div className="image-calendar-container">
            <img 
            className="concert-image-background" 
            src="../photos/DSCF7486.webp"
            />
        </div>
        {showMenuHamburger && <MenuHamburger isMenuOpen={isMenuOpen} isConcertsPage={true} language={language} />}
        {!showMenuHamburger && <Header isConcertsPage={true} language={language}/>}
        <Link to="scrollTarget" smooth={true} duration={1500} className="down-arrow-link">
        <div className="down-arrow-bio">
          <svg className="down-arrow" xmlns="http://www.w3.org/2000/svg" fillRule="evenodd" clipRule="evenodd"><path d="M24 12c0-6.623-5.377-12-12-12s-12 5.377-12 12 5.377 12 12 12 12-5.377 12-12zm-1 0c0-6.071-4.929-11-11-11s-11 4.929-11 11 4.929 11 11 11 11-4.929 11-11zm-11.5 4.828l-3.763-4.608-.737.679 5 6.101 5-6.112-.753-.666-3.747 4.604v-11.826h-1v11.828z"/></svg>
        </div>
        </Link>
        <div id="scrollTarget" className="calendar-body">
            <div className="calendar-title-container">
                <h1 className="calendar-title">Kalender</h1>
                <div className="line2"></div>
            </div>
            <div className="concerts-grid-container">
              <div className="concerts-grid">
                <ConcertItem language={language} date="14.04.2025 - 17.04.2025" nameOfConcert="Festival Academy Starnberger Musiktage - Korrepetition beim Meisterkurs von Prof. Malte Refardt (Fagott) und Prof. Johannes Gmeinder (Klarinette)" place="Starnberger, Germany" whoPlays1="Tetiana Bielikova, Korrepetition" link="https://starnbergermusiktage.de/festival-academy/meisterkurse"/>
                <ConcertItem 
                  language={language} 
                  date="11.07.2025" 
                  time="19:30" 
                  nameOfConcert={"\"Folkwang Fagottissimo\" - Studierende der Fagottklasse Prof. Malte Refardt"} 
                  place="Folkwang Universität der Künste - Kammermusiksaal, Essen, Deutschland" 
                  whoPlays1="Tetiana Bielikova, Korrepetition"
                />
                {/* <ConcertItem language={language} 
                  date="30.07.2024 - 09.08.2024" 
                  nameOfConcert={"Korrepetition bei dem Meisterkurs \"Sommermusik im Oberen Nagoldtal\""}
                  place="Nagold, Germany" 
                  link="https://www.sommermusik-nagoldtal.de/"
                /> */}
              </div>
            </div>
        </div>
        <Footer/>
    </div>
    </>
  );
};

export default ConcertsDE;
